import { stringify } from 'query-string';
import { fetchUtils, DataProvider } from 'ra-core';
import { ValidationErrorMessageWithArgs } from 'react-admin';
import { hasProperty } from 'src/utils/function';
import { CreatePdfPostData } from './utils/dataProvider/createPdfPostData';

interface APIValidationError {
  errors: Array<{
    path: string;
    message: string;
  }>;
}

export function isAPIValidationError(error: unknown): error is APIValidationError {
  return (
    hasProperty(error, 'status', 'errors') &&
    error.status === 400 &&
    Array.isArray(error.errors) &&
    error.errors.every((e) => hasProperty(e, 'path', 'message'))
  );
}

export function collectErrorsAsObject(
  error: APIValidationError,
  prefix = '.body.',
): Record<string, ValidationErrorMessageWithArgs> {
  const result: Record<string, ValidationErrorMessageWithArgs> = {};

  error.errors.forEach(({ path, message }) => {
    if (path.startsWith(prefix)) {
      // https://github.com/marmelab/react-admin/blob/v4.10.3/packages/ra-ui-materialui/src/input/InputHelperText.tsx
      // バグだと思われるが、ValidationErrorに渡らないとtranslateされないのでobjectにする
      result[path.substring(prefix.length)] = { message, args: {} };
    }
  });

  return result;
}

const dataProvider = (apiUrl: string, httpClient = fetchUtils.fetchJson): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sort: field,
      sortOrder: order,
      page,
      perPage,
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json.data,
        total: json.total,
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json.data,
    })),

  getMany: (resource, params) => {
    const query = stringify({
      ids: params.ids,
      ...params.meta,
    });
    const url = `${apiUrl}/${resource}/ids?${query}`;
    return httpClient(url).then(({ json }) => ({ data: json.data }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sort: field,
      sortOrder: order,
      page,
      perPage,
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json.data,
        total: json.total,
      };
    });
  },

  update: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;

    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json.data }));
  },

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) => {
    return Promise.all(
      params.ids.map((id) => {
        const url = `${apiUrl}/${resource}/${id}`;
        return httpClient(url, {
          method: 'POST',
          body: JSON.stringify(params.data),
        });
      }),
    ).then((responses) => ({ data: responses.map(({ json }) => json.data.id) }));
  },

  create: (resource, params) => {
    const url = `${apiUrl}/${resource}`;

    if (resource === 'pdf') {
      const formData = CreatePdfPostData(params);
      return fetchUtils
        .fetchJson(url, {
          method: 'POST',
          body: formData,
        })
        .then(({ json }) => ({ data: json }));
    }

    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json.data }));
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    }).then(({ json }) => ({ data: json.data })),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'text/plain',
          }),
        }),
      ),
    ).then((responses) => ({
      data: responses.map(({ json }) => json.data.id),
    })),

  invoke: (id: string, params: object) =>
    httpClient(`${apiUrl}/${id}`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json.data })),
});

export default dataProvider;
