import * as React from 'react';
import { FileForm } from '../utils/dataProvider/createPdfPostData';
import { BooleanInput, FileField, FileInput, required, SimpleForm, useDataProvider } from 'react-admin';

const MAX_PDF_COUNT = 100;

export type PdfUploadValidationError = {
  message: string;
  ISBNs: Array<string>;
};

export const PdfUploadForm: React.FC<{ setErrors: (errors: PdfUploadValidationError[] | null) => void }> = ({
  setErrors,
}) => {
  type FormParams = {
    pdfs: Array<FileForm>;
    forceUpload: boolean;
  };
  type FormParamsErrors = { [key in keyof FormParams]?: string };

  const dp = useDataProvider();

  const validate = async (values: Record<string, unknown>): Promise<FormParamsErrors> => {
    const data = values as FormParams;
    const errors: Array<PdfUploadValidationError> = [];
    const createResult = (err: Array<PdfUploadValidationError>) => {
      if (err.length === 0) {
        setErrors(null);
        return {};
      }
      setErrors(err);
      return { pdfs: 'validation error' };
    };
    try {
      if (data.pdfs.length > MAX_PDF_COUNT) {
        errors.push({
          message: `一度にアップロードできるPDFは ${MAX_PDF_COUNT} ファイルまでです。`,
          ISBNs: [],
        });
        return createResult(errors);
      }

      const inputISBNs = data.pdfs.map((pdf) => pdf.title.replace(/\.[^/.]+$/, ''));
      const books = await dp.getList('reviewedBooks', {
        pagination: { page: 1, perPage: MAX_PDF_COUNT },
        sort: { field: 'id', order: 'ASC' },
        filter: { isbn: inputISBNs },
      });
      if (books.data.length !== data.pdfs.length) {
        const booksOnDB = books.data.map((book) => book.isbn);
        const missingISBNs = inputISBNs.filter((isbn) => !booksOnDB.includes(isbn));
        errors.push({
          message: 'レビュー済み書誌情報に登録されていない書籍が含まれています。',
          ISBNs: missingISBNs,
        });
      }

      type PdfGetManyResponse = {
        id: string;
      };

      if (!data.forceUpload) {
        const pdfListRes = await dp.getMany<PdfGetManyResponse>('pdf', {
          ids: inputISBNs,
        });
        const alreadyExistingISBNs = pdfListRes.data.map((pdf) => pdf.id);
        if (alreadyExistingISBNs.length > 0) {
          errors.push({
            message: '以下のISBNのPDFは既にアップロードされています。',
            ISBNs: alreadyExistingISBNs,
          });
        }
      }
    } catch (e) {
      console.error(e);
      errors.push({
        message: '予期せぬエラーが発生しました。',
        ISBNs: [],
      });
    }
    return createResult(errors);
  };

  return (
    <SimpleForm validate={validate}>
      <BooleanInput label="上書きアップロードを許可" source="forceUpload" validate={required()} />
      <FileInput label="PDF" source="pdfs" accept="application/pdf" multiple validate={required()}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  );
};
