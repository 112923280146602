import japaneseMessages from '@bicstone/ra-language-japanese';
import { TranslationMessages } from 'ra-core';
const bookTranslationField = {
  id: 'id',
  isbn: 'ISBN',
  latestIsbn: '最新版ISBN',
  operator: '作業者',
  confirmerId: 'チェック者',
  workStatus: '作業状況',
  publisherInfo: {
    id: '出版社ID',
    name: '出版社名',
  },
  pdfDataUrl: 'GoogleDriveのURL',
  allowPDFPublish: 'PDF掲載',
  allowWebPublish: 'Web掲載',
  allowDownloadAndPrint: '印刷・ダウンロード',
  allowCopyContent: '内容のコピー',
  allowAddLink: 'リンク付与可否',
  tosNotAllowedPage: '掲載不可ページ',
  isExistCoverImage: '表紙画像の有無',
  bookTitle: '表紙上の全タイトル',
  coverAuthor: '表紙上の執筆者',
  colophonPageNumber: '奥付のページ番号',
  colophonAuthor: '著者・編集者・編者・編著者・監修者',
  bookVersion: '版',
  startPublicationDate: '発行年月日',
  fullTitle: '全タイトル',
  printerInfo: {
    id: '印刷所ID',
    name: '印刷所名',
  },
  lsStartPublicationDate: 'LS 掲載開始日',
  lsEndPublicationDate: 'LS 掲載終了日',
  printing: '刷',
  bookType: '種別',
  clientCode: '先方指定コード',
  purchaseType: '販売形式',
  reservableFrom: '予約開始日',
  docInfo: {
    id: 'Doc/ES',
    packIds: 'ESパックID',
    purchaseType: 'ES販売形式',
  },
  docCategoryTripleMajor: '書誌カテゴリ Major',
  docCategoryTripleMiddle: '書誌カテゴリ Middle',
  docCategoryTripleMinor: '書誌カテゴリ Minor',
};
const docRecordFields = {
  // docRecord からの抜粋
  id: 'ID',
  type: 'Docタイプ',
  title: '全タイトル',
  authors: '表紙上の執筆者',
  publisher: '出版社',
  packIds: 'パックID',
  planIds: 'プランID',
  availableUntil: '公開終了日',
  availableFrom: '公開開始日',
  purchaseType: '販売形式',
  reservableFrom: '予約開始日',
};

const translation: TranslationMessages = {
  ...japaneseMessages,
  resources: {
    organizations: {
      name: '組織',
      fields: {
        name: '名前',
        subdomain: 'サブドメイン',
        oid: 'OID',
        pricingType: '料金計算方式',
        contractUserCountType: '契約ユーザー数の種別',
        maxContractUserCount: '最大契約ユーザー数',
        activeContractUserCount: '有効な契約ユーザー数',
        trialFrom: 'トライアル開始日',
        trialUntil: 'トライアル終了日',
        paidFrom: '本利用開始日',
        paidUntil: '本利用終了日',
        permissions: 'パーミッション',
        'permissions.id': 'パーミッション',
        'permissions.validFrom': '適用開始日',
        'permissions.validUntil': '適用終了日',
        packId: 'パックID',
        validFrom: '利用開始日',
        validUntil: '利用終了日',
        loginType: 'ログイン種別',
        organizationType: '組織種別',
        isLegalOn: 'LegalOn 販売組織',
        packs: '出版社パック',
        'packs.packId': 'パックID',
        'packs.validFrom': '利用開始日',
        'packs.validUntil': '利用終了日',
        jobTitles: '肩書一覧',
        'jobTitles.name': '肩書名',
        'jobTitles.billable': '課金対象かどうか',
        sellerId: '販売経路',
        labsWandhPlans: 'W&Hプラン構成',
        'labsWandhPlans.basePlanId': '基本プラン',
        'basePlan.name': '基本プラン',
        'labsWandhPlans.addOnPlanId': '追加プラン',
        'addOnPlan.name': '追加プラン',
        'labsWandhPlans.maxAddOnPlanUsers': '追加プランの最大ユーザー数',
        'labsWandhPlans.activeFrom': '利用開始日時',
        'labsWandhPlans.activeUntil': '利用終了日時',
        lawFirmTieredPricingCondition: '法律事務所ティアードプラン 詳細設定',
        'lawFirmTieredPricingCondition.orgId': '組織ID',
        'lawFirmTieredPricingCondition.pageViewCountThreshold': 'ページビュー閾値',
        'lawFirmTieredPricingCondition.searchCountThreshold': '検索回数閾値',
        'lawFirmTieredPricingCondition.availableDaysThreshold': '利用可能日数閾値',
        'lawFirmTieredPricingCondition.minChargeUserCount': '最小課金ユーザー数',
        'lawFirmTieredPricingCondition.maxChargeUserCount': '最大課金ユーザー数',
        'lawFirmTieredPricingCondition.minChargeUserPercent': '最小課金ユーザー割合',
        'lawFirmTieredPricingCondition.maxChargeUserPercent': '最大課金ユーザー割合',
        'lawFirmTieredPricingCondition.createdAt': '作成日時',
        'lawFirmTieredPricingCondition.updatedAt': '更新日時',
        maxAddOnPlanUsers: '追加プランの最大ユーザー数',
        activeFrom: '利用開始日時',
        activeUntil: '利用終了日時',
        labsWandhD1Option: '判例連携オプション',
        'labsWandhD1Option.validFrom': '利用開始日時',
        'labsWandhD1Option.validUntil': '利用終了日時',
        'labsWandhD1Option.type': '適用方法',
      },
    },
    adminUsers: {
      name: '管理画面ユーザー',
      fields: {
        id: 'ID',
        firebaseId: 'Firebase ID',
        name: '名前',
        createdAt: '作成日',
      },
    },
    users: {
      name: 'ユーザー',
      fields: {
        id: 'ID',
        firebaseId: 'Firebase ID',
        name: '名前',
        email: 'メールアドレス',
        objectId: 'オブジェクトID',
        validFrom: '開始',
        validUntil: '終了',
        createdAt: '作成日',
        jobTitleName: '肩書',
        jobTitleBillable: '課金',
      },
      resetPassword: {
        title: 'ユーザーのパスワードリセット',
      },
    },
    permissions: {
      name: 'パーミッション',
      fields: {
        id: 'ID',
        name: '名前',
        description: '説明',
        permissionIds: 'パーミッション',
      },
    },
    trialUserRequests: {
      name: 'トライアル',
      fields: {
        id: 'ID',
        trialUrl: 'トライアル申請URL',
        subdomain: 'サブドメイン',
        requestStatus: 'ステータス',
        createdAt: '作成日時',
        updatedAt: '更新日時',
        trialUserRequestDetails: 'トライアル申請',
        name: '名前',
        email: 'メールアドレス',
        objectId: 'オブジェクトID',
        validFrom: '課金開始日',
      },
      action: {
        create: 'トライアル申請URL発行',
      },
    },
    requests: {
      name: '申請',
      fields: {
        id: 'ID',
        requestStatus: 'ステータス',
        requestType: '申請種別',
        createdAt: '作成日時',
        updatedAt: '更新日時',
        addUserRequestDetails: '追加申請',
        editUserRequestDetails: '編集申請',
        deleteUserRequestDetails: '削除申請',
        enrollmentRequestDetail: '本利用申請',
        userId: 'ユーザーID',
        requestUser: {
          name: '申請者名',
        },
        name: '名前',
        jobTitleId: '肩書',
        newJobTitleName: '新しい肩書',
        billable: '課金対象',
        email: 'メールアドレス',
        objectId: 'オブジェクトID',
        preferredDate: '希望日',
        jobTitle: {
          id: 'ID',
          name: '肩書名',
          billable: '課金対象かどうか',
          createdAt: '作成日時',
          updatedAt: '更新日時',
        },
        organization: {
          name: '組織名',
          pricingType: 'プライシングタイプ',
        },
        oid: '組織ID',
        userCountTimeline: '有効アカウント数',
      },
    },
    tasks: {
      name: '開発者用機能',
      fields: {
        id: 'タスク名',
      },
      ids: {
        recreateAllOrgViews: '組織横断ビュー再構築',
      },
    },
    books: {
      name: '書誌情報',
      fields: bookTranslationField,
    },
    reviewedBooks: {
      name: 'レビュー済み書誌情報',
      fields: bookTranslationField,
    },
    docs: {
      name: '登録済み書誌情報',
      fields: docRecordFields,
    },
    publishers: {
      name: '出版社',
      fields: {
        id: 'id',
        name: '出版社名',
      },
    },
    printers: {
      name: '印刷所',
      fields: {
        id: 'id',
        name: '印刷所名',
      },
    },
    pdf: {
      name: 'PDFアップロード',
    },
    packs: {
      name: 'パック',
    },
    formatCategories: {
      name: '書式カテゴリ',
      fields: {
        id: 'ID',
        name: 'カテゴリ名',
        createdAt: '作成日時',
        updatedAt: '更新日時',
      },
    },
    formatSubcategories: {
      name: '書式サブカテゴリ',
      fields: {
        id: 'ID',
        formatCategoryId: '書式カテゴリ',
        name: 'サブカテゴリ名',
        createdAt: '作成日時',
        updatedAt: '更新日時',
      },
    },
    formatUses: {
      name: '書式目的・用途',
      fields: {
        id: 'ID',
        formatSubcategoryId: '書式サブカテゴリ',
        name: '目的・用途名',
        createdAt: '作成日時',
        updatedAt: '更新日時',
      },
    },
    labsWandhPlans: {
      name: 'W&Hプラン',
      fields: {
        id: 'ID',
        name: 'プラン名',
        maxSearchTotal: '合計検索回数の上限',
        maxSearchBoost: '上位モデル検索回数の上限',
        createdAt: '作成日時',
        updatedAt: '更新日時',
        deletedAt: '削除日時',
      },
    },
    lawFirmTieredPricingConditions: {
      name: '法律事務所ティアードプラン 詳細設定',
      fields: {
        orgId: '組織ID',
        pageViewCountThreshold: 'ページビュー閾値',
        searchCountThreshold: '検索回数閾値',
        availableDaysThreshold: '利用可能日数閾値',
        minChargeUserCount: '最小課金ユーザー数',
        maxChargeUserCount: '最大課金ユーザー数',
        minChargeUserPercent: '最小課金ユーザー割合',
        maxChargeUserPercent: '最大課金ユーザー割合',
        createdAt: '作成日時',
        updatedAt: '更新日時',
      },
    },
    jobTitles: {
      name: '肩書',
      fields: {
        id: 'ID',
        name: '肩書名',
        billable: '課金対象',
        createdAt: '作成日時',
        updatedAt: '更新日時',
        deletedAt: '削除日時',
      },
    },
  },
  enum: {
    pricingType: {
      lawFirm: '旧プラン（法律事務所）',
      companyLegalDepartment: '旧プラン（企業法務部）',
      lawFirmSmall: '法律事務所30名以下',
      lawFirmMedium: '法律事務所31名以上100名以下',
      lawFirmLarge: '法律事務所101名以上',
      lawFirmBig5: '法律事務所五大',
      companyLegalDepartmentNormal: '企業法務部通常プラン',
      lawFirmCustomized: '法律事務所カスタム（廃止済み）',
      companyLegalDepartmentOtameshi: '企業法務部お試しプラン',
      legalOn: 'LegalOn 販売プラン',
    },
    contractUserCountType: {
      fixed: '固定型',
      variable: '変動型',
    },
    trialRequestStatus: {
      beforeApplication: '申請前',
      applying: '申請中',
      processing: '処理中',
      treated: '処理済',
      rejected: '棄却',
    },
    requestStatus: {
      applying: '受理待ち',
      treated: '処理済み',
      rejected: '棄却',
    },
    requestType: {
      addUserRequest: 'ユーザー追加申請',
      editUserRequest: 'ユーザー修正申請',
      deleteUserRequest: 'ユーザー削除申請',
      enrollmentRequest: '本利用申請',
    },
    loginType: {
      password: 'パスワード認証',
      microsoft: 'Microsoft認証',
      google: 'Google認証',
      emailLink: 'メールリンク認証',
    },
    organizationTypeId: {
      '1': '企業法務部',
      '2': '法律事務所',
      '3': '士業事務所（法律事務所は除く）',
      '999': 'その他',
    },
    sellerId: {
      1: '直販',
      2: 'LegalOn Technologies',
    },
    allowPDFPublish: {
      false: 'なし',
      true: 'あり',
    },
    allowWebPublish: {
      false: 'なし',
      true: 'あり',
    },
    allowDownloadAndPrint: {
      false: 'なし',
      true: 'あり',
    },
    allowCopyContent: {
      false: 'なし',
      true: 'あり',
    },
    allowAddLink: {
      false: 'なし',
      true: 'あり',
    },
    isExistCoverImage: {
      false: 'なし',
      true: 'あり',
    },
    workStatus: {
      pdfRegistered: 'PDF登録済み',
      bibliographicInfoEntered: '書誌情報入力済み',
      reviewed: 'レビュー済み',
      remand: '差し戻し',
    },
    bookType: {
      book: '書籍',
      magazine: '雑誌',
    },
    purchaseType: {
      default: 'デフォルト',
      purchasable: 'ストア販売あり',
    },
    esPurchaseType: {
      '1': 'デフォルト',
      '2': 'ストア販売あり',
    },
    labsWandhD1OptionType: {
      '1': '全ユーザー適用',
      '2': '組織適用',
      '3': '指定ユーザー適用',
    },
  },
  error: {
    trialUntilShouldBeAfterTrialFrom: 'トライアル終了日はトライアル開始日より後の日時を指定してください',
    paidUntilShouldBeAfterPaidFrom: '本利用終了日は本利用開始日より後の日時を指定してください',
    packValidUntilShouldBeAfterPackValidFrom: 'パック利用終了日はパック利用開始日より後の日時を指定してください',
    invalidOrganizationStateWithinTrialWithinPaid: 'トライアルと本利用の期間を重複させることはできません',
    invalidOrganizationStateBeforeTrialWithinPaid:
      '本利用期間中にトライアル予定を設定すること（またはその逆）はできません',
    invalidOrganizationStateBeforeTrialBeforePaid: '本利用開始予定日はトライアルが開始した後に記入してください',
    invalidOrganizationStateBeforeTrialAfterPaid:
      '本利用終了後にトライアル予定を設定することはできません。開発者にご連絡ください',
    invalidOrganizationStateWithinTrialAfterPaid:
      '本利用終了後にトライアル中にすることはできません。開発者にご連絡ください',
    duplicatedSubdomain: 'すでに同じサブドメインが存在します',
    notFound: 'データが見つかりませんでした',
    conflict: 'データ更新前の競合を検知しました。ページ更新後、再度実行してください。',
    hasOlderApplyingRequest: 'この申請よりも過去の未処理の申請があるため実行できません。その申請の対応をお願いします。',
    unknown: 'エラーが発生しました。開発者にご連絡ください。',
    emailAlreadyExists: 'このメールアドレスはすでに存在します',
    activeUntilShouldBeAfterActiveFrom: 'W&Hプラン利用終了日時は利用開始日時より後の日時を指定してください',
    labsWandhPlanInsufficient: 'W&Hプラン構成を物理削除することはできません',
    labsWandhPlanChangeNotAllowed: '既に構成されたプランを別のプランに変更することはできません。新規追加してください',
    labsWandhPlanMaxAddOnUsersDecreaseNotAllowed: '追加プランの最大ユーザー数の減少はできません',
    labsWandhPlanActiveUntilChangeAfterPassedNotAllowed: '利用終了日時は到来後変更できません',
    labsWandhPlanMaxAddOnUsersChangeAfterActiveUntilPassedNotAllowed:
      '既に終了した構成の追加プランの最大ユーザー数の変更はできません',
    labsWandhPlanOverlapping: 'W&Hプランの利用期間が重複しています',
    multipleLabsWandhPlansAtOrgCreation: '組織作成時に複数のW&Hプランを設定することはできません',
    maxAddOnPlanUsersRequired: '追加プランの最大ユーザー数を入力してください',
  },
  validation: {
    openapi: {
      serdes: '入力形式に誤りがあります',
    },
  },
  notification: {
    simpleUpdated: '更新しました',
    completed: '完了しました',
  },
  label: {
    invoke: '実行する',
    reset: 'リセット',
  },
  messages: {
    confirm_invoke: '%{name}を実行します。よろしいですか？',
    cannotTreatBecauseOfOrganizationState:
      '組織の契約期間によりこの申請は承認できません。詳しくは開発者に確認してください',
    createUser: '作成したユーザーのパスワードは %{password} です。一度しか表示しないためここでメモしてください。',
    resetUserPassword: '新しいユーザーのパスワードは %{password} です。一度しか表示しないためここでメモしてください。',
  },
};

export default translation;
