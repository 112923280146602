import { CreateParams } from 'react-admin';

export type FileForm = {
  rawFile: File;
  src: string;
  title: string;
};

type CreatePdfPostParams = {
  pdfs: Array<FileForm>;
};

export const CreatePdfPostData = (params: CreateParams<CreatePdfPostParams>) => {
  const formData = new FormData();
  for (let i = 0; i < params.data.pdfs.length; i++) {
    formData.append(`pdfs[${i}]`, params.data.pdfs[i].rawFile);
  }

  return formData;
};
