import * as React from 'react';
import type { Validator } from 'react-admin';
import {
  ArrayInput,
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  NumberInput,
  useTranslate,
  number,
  minValue,
  maxValue,
} from 'react-admin';
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import DatetimeInput from 'src/components/DatetimeInput';
import CustomDateTimeInput from 'src/components/DatetimeInput';
import { labsWandhD1OptionTypes } from './field';

export const PermissionsInput: React.FC = () => {
  const defaultValidFrom = new Date();
  defaultValidFrom.setHours(0, 0, 0, 0);

  type ValidatePermission = (
    value: number,
    values: { permissions: { id: number }[] },
    props: { source: string },
  ) => string | undefined;
  const validatePermission: ValidatePermission = (value, values, props) => {
    const { source } = props;
    const index = Number(source.split('.')[1]);

    let error;
    // パーミッション ID が重複しているものだけを抽出
    const duplicates = values.permissions.map(({ id }, i) => ({ id, i })).filter(({ id }) => id === value);
    // 重複が自分自身以外の場合はバリデーションエラー
    if (duplicates.findIndex(({ i }) => i === index) !== 0) {
      error = '権限が重複しています';
    }

    return error;
  };

  type ValidateValidUntil = (scopedFormData: {
    id: number;
    validFrom: Date | string;
    validUntil: Date | string | null;
  }) => Validator;
  const validateValidUntil: ValidateValidUntil = (scopedFormData) => {
    return (validUntil: Date | string | null, _values, _props) => {
      let error;
      const { validFrom } = scopedFormData;
      const _validFrom = validFrom ? new Date(validFrom) : validFrom;
      const _validUntil = validUntil ? new Date(validUntil) : validUntil;
      if (_validUntil && _validFrom && _validUntil <= _validFrom) {
        error = '適用終了日は適用開始日より未来の日付を入力してください';
      }

      return error;
    };
  };

  return (
    <>
      <ArrayInput source="permissions">
        <SimpleFormIterator disableReordering inline>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) =>
              getSource && (
                <>
                  <ReferenceInput
                    reference="permissions"
                    source={getSource('id')}
                    validate={[required()]}
                    sort={{ field: 'id', order: 'ASC' }}
                    perPage={1000}
                  >
                    <SelectInput optionText="name" validate={[required(), validatePermission]} />
                  </ReferenceInput>
                  <DatetimeInput
                    source={getSource('validFrom')}
                    defaultValue={defaultValidFrom}
                    validate={[required()]}
                  />
                  <DatetimeInput source={getSource('validUntil')} validate={validateValidUntil(scopedFormData)} />
                </>
              )
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

/**
 * LawFirmTieredPricingCondition の値を入力するための Input 群
 */
export const LawFirmTieredPricingConditionInput: React.FC = () => {
  const translate = useTranslate();
  const fields = ['minChargeUserCount', 'maxChargeUserCount', 'minChargeUserPercent', 'maxChargeUserPercent'];

  const translation = fields.reduce((acc, field) => {
    acc[field] = translate(`resources.lawFirmTieredPricingConditions.fields.${field}`);
    return acc;
  }, {} as Record<string, string>);

  type Value = number | null | undefined;
  type AllValue = {
    lawFirmTieredPricingCondition: {
      pageViewCountThreshold: Value;
      searchCountThreshold: Value;
      availableDaysThreshold: Value;
      minChargeUserCount: Value;
      maxChargeUserCount: Value;
      minChargeUserPercent: Value;
      maxChargeUserPercent: Value;
    };
  };

  const validateInteger = (value: Value) => {
    if (typeof value !== 'number') return;

    if (!Number.isInteger(value)) {
      return `整数を入力してください。`;
    }
  };

  const validateMutualExclusion = (value: Value, otherValue: Value, fieldName: string, otherFieldName: string) => {
    if (value && otherValue) {
      return `${translation[fieldName]}と${translation[otherFieldName]}は同時に指定できません。`;
    }
  };

  const validateRequiredInput = (value: Value, otherValue: Value, fieldName: string, otherFieldName: string) => {
    if (!value && value !== 0 && !otherValue) {
      return `${translation[fieldName]}と${translation[otherFieldName]}のどちらかに値を入力してください。`;
    }
  };

  const validateRange = (value: Value, otherValue: Value, fieldName: string, comparison: 'greater' | 'less') => {
    if (typeof value !== 'number' || typeof otherValue !== 'number') return;

    if (comparison === 'greater' && value <= otherValue) {
      return `${translation[fieldName]}より大きい値を入力してください。`;
    }
    if (comparison === 'less' && value >= otherValue) {
      return `${translation[fieldName]}より小さい値を入力してください。`;
    }
  };

  const validateMinChargeUserCount = (
    value: Value,
    { lawFirmTieredPricingCondition: { minChargeUserPercent, maxChargeUserCount } }: AllValue,
  ) => {
    const requiredError = validateRequiredInput(
      value,
      minChargeUserPercent,
      'minChargeUserCount',
      'minChargeUserPercent',
    );
    if (requiredError) return requiredError;

    const mutualExclusionError = validateMutualExclusion(
      value,
      minChargeUserPercent,
      'minChargeUserCount',
      'minChargeUserPercent',
    );
    if (mutualExclusionError) return mutualExclusionError;

    return validateRange(value, maxChargeUserCount, 'maxChargeUserCount', 'less');
  };

  const validateMinChargeUserPercent = (
    value: Value,
    { lawFirmTieredPricingCondition: { minChargeUserCount, maxChargeUserPercent } }: AllValue,
  ) => {
    const requiredError = validateRequiredInput(
      value,
      minChargeUserCount,
      'minChargeUserPercent',
      'minChargeUserCount',
    );
    if (requiredError) return requiredError;

    const mutualExclusionError = validateMutualExclusion(
      value,
      minChargeUserCount,
      'minChargeUserPercent',
      'minChargeUserCount',
    );
    if (mutualExclusionError) return mutualExclusionError;

    return validateRange(value, maxChargeUserPercent, 'maxChargeUserPercent', 'less');
  };

  const validateMaxChargeUserCount = (
    value: Value,
    { lawFirmTieredPricingCondition: { maxChargeUserPercent, minChargeUserCount } }: AllValue,
  ) => {
    const requiredError = validateRequiredInput(
      value,
      maxChargeUserPercent,
      'maxChargeUserCount',
      'minChargeUserPercent',
    );
    if (requiredError) return requiredError;

    const mutualExclusionError = validateMutualExclusion(
      value,
      maxChargeUserPercent,
      'maxChargeUserCount',
      'maxChargeUserPercent',
    );
    if (mutualExclusionError) return mutualExclusionError;

    return validateRange(value, minChargeUserCount, 'minChargeUserCount', 'greater');
  };

  const validateMaxChargeUserPercent = (
    value: Value,
    { lawFirmTieredPricingCondition: { maxChargeUserCount, minChargeUserPercent } }: AllValue,
  ) => {
    const requiredError = validateRequiredInput(
      value,
      maxChargeUserCount,
      'maxChargeUserPercent',
      'maxChargeUserCount',
    );
    if (requiredError) return requiredError;

    const mutualExclusionError = validateMutualExclusion(
      value,
      maxChargeUserCount,
      'maxChargeUserPercent',
      'maxChargeUserCount',
    );
    if (mutualExclusionError) return mutualExclusionError;

    return validateRange(value, minChargeUserPercent, 'minChargeUserPercent', 'greater');
  };

  // 共通のバリデーション設定
  const commonValidation = [number(), validateInteger, minValue(0)];

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        // 料金プランが 3,4,5,6 のいずれかの場合にのみ表示する
        ['lawFirmSmall', 'lawFirmMedium', 'lawFirmLarge', 'lawFirmBig5'].includes(formData.pricingType) && (
          <>
            <Typography variant="body2">{translate('resources.lawFirmTieredPricingConditions.name')}</Typography>

            <Grid container spacing={1} columns={16}>
              <Grid item xs={2}>
                <NumberInput
                  source="lawFirmTieredPricingCondition.pageViewCountThreshold"
                  validate={commonValidation}
                  step={1}
                  {...rest}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="lawFirmTieredPricingCondition.searchCountThreshold"
                  validate={commonValidation}
                  step={1}
                  {...rest}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="lawFirmTieredPricingCondition.availableDaysThreshold"
                  validate={commonValidation}
                  step={1}
                  {...rest}
                />
              </Grid>
              <Grid item xs={10} />

              <Grid item xs={2}>
                <NumberInput
                  source="lawFirmTieredPricingCondition.minChargeUserCount"
                  validate={[...commonValidation, validateMinChargeUserCount]}
                  step={1}
                  {...rest}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="lawFirmTieredPricingCondition.maxChargeUserCount"
                  validate={[...commonValidation, validateMaxChargeUserCount]}
                  step={1}
                  {...rest}
                />
              </Grid>
              <Grid item xs={12} />

              <Grid item xs={2}>
                <NumberInput
                  source="lawFirmTieredPricingCondition.minChargeUserPercent"
                  validate={[...commonValidation, maxValue(100), validateMinChargeUserPercent]}
                  step={1}
                  {...rest}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="lawFirmTieredPricingCondition.maxChargeUserPercent"
                  validate={[...commonValidation, maxValue(100), validateMaxChargeUserPercent]}
                  step={1}
                  {...rest}
                />
              </Grid>
              <Grid item xs={12} />
            </Grid>
          </>
        )
      }
    </FormDataConsumer>
  );
};

/**
 * 判例連携オプションの値を入力するための Input 群
 */
export const LabsWandhD1OptionInput: React.FC = () => {
  const translate = useTranslate();
  const [useOption, setOption] = React.useState(false);

  const optionTypes = {
    allUsers: '1',
    organization: '2',
    specifiedUsers: '3',
  } as const;

  const filteredOptionTypes = (pricingType: string | null) => {
    if (pricingType === null) {
      return labsWandhD1OptionTypes;
    }

    // 法律事務所
    const lawFirmTypes = [
      'lawFirm',
      'lawFirmSmall',
      'lawFirmMedium',
      'lawFirmLarge',
      'lawFirmBig5',
      'lawFirmCustomized',
    ];

    // 企業
    const legalDepartmentTypes = [
      'companyLegalDepartment',
      'companyLegalDepartmentNormal',
      'companyLegalDepartmentOtameshi',
      'legalOn',
    ];

    return labsWandhD1OptionTypes.filter((type) => {
      // 法律事務所の場合は「全ユーザー適用」を除外
      if (lawFirmTypes.includes(pricingType)) {
        return type.id !== optionTypes.allUsers;
      }

      // 企業の場合は「組織適用」を除外
      if (legalDepartmentTypes.includes(pricingType)) {
        return type.id !== optionTypes.organization;
      }

      return true;
    });
  };

  return (
    <Box pt={0.5}>
      <Typography variant="caption" color="textSecondary">
        {translate('resources.organizations.fields.labsWandhD1Option')}
      </Typography>

      <FormDataConsumer>
        {({ formData }) => (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  id="useLabsWandhD1Option"
                  size="small"
                  value={useOption}
                  onChange={(e) => setOption(e.target.checked)}
                />
              }
              label="利用する"
              sx={{ display: 'block', '.MuiFormControlLabel-label': { fontSize: '14px' } }}
            />

            {useOption && (
              <>
                <Box display="flex" gap="1em" alignItems="center">
                  <CustomDateTimeInput source="labsWandhD1Option.validFrom" validate={required()} />
                  <CustomDateTimeInput source="labsWandhD1Option.validUntil" />
                </Box>
                <SelectInput
                  source="labsWandhD1Option.type"
                  choices={filteredOptionTypes(formData.pricingType)}
                  validate={required()}
                  sx={{ minWidth: 208 }}
                />
              </>
            )}
          </>
        )}
      </FormDataConsumer>
    </Box>
  );
};
