import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  EditButton,
  Pagination,
  ReferenceArrayField,
  ReferenceInput,
  AutocompleteInput,
  FunctionField,
} from 'react-admin';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DatetimeField from 'src/components/DatetimeField';
import DebouncedTextInput from 'src/components/DebouncedTextInput';

type DocRecordType = 'book' | 'pdf' | 'html' | 'pubcom' | 'guideline' | 'law';
export type DocRecord = {
  // 抜粋...
  id: string;
  type: DocRecordType;
  title: string;
  authors: string[] | null;
  publisher?: string;
  packIds: string[];
  planIds?: string[];
  availableUntil?: string;
  availableFrom?: string;
  purchaseType?: number;
  reservableFrom?: string;
};

export const DocIcon = MenuBookIcon;

const ListActions: React.FC = () => <TopToolbar />;
const filters = [
  <DebouncedTextInput source="id" alwaysOn />,
  <DebouncedTextInput source="title" alwaysOn />,
  <DebouncedTextInput source="authors" alwaysOn />,
  <ReferenceInput source="publisher" reference="publishers" alwaysOn>
    <AutocompleteInput optionText="name" optionValue="name" label="resources.publishers.name" sx={{ width: 300 }} />
  </ReferenceInput>,
];

export const DocList: React.FC = () => {
  return (
    <List
      actions={<ListActions />}
      filters={filters}
      perPage={1000}
      pagination={<Pagination rowsPerPageOptions={[10, 100, 1000]} />}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" sortable={true} />
        <TextField source="title" sortable={false} />
        <FunctionField
          source="authors"
          sortable={false}
          render={(record?: { authors: string[] }) => {
            return record?.authors?.join('、');
          }}
        />
        <TextField source="publisher" sortable={false} />
        <ReferenceArrayField source="packIds" reference="packs" sortable={false} />
        <DatetimeField source="availableFrom" sortable={true} />
        <DatetimeField source="availableUntil" sortable={true} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export * from './show';
export * from './update';
