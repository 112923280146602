import { Create, useRedirect } from 'react-admin';
import * as React from 'react';
import { PdfUploadForm, PdfUploadValidationError } from '../../components/PdfUploadForm';

const ErrorMessages: React.FC<{ errors: PdfUploadValidationError[] | null }> = ({ errors }) => {
  if (!errors) {
    return null;
  }
  return (
    <div style={{ color: 'red' }}>
      {errors.map((error) => (
        <div key={error.message}>
          {error.message}
          <ul>
            {error.ISBNs.map((isbn) => (
              <li key={isbn}>{isbn}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export const PdfList: React.FC = () => {
  const redirect = useRedirect();
  React.useEffect(() => {
    redirect('/pdf/create');
  });
  return null;
};

export const PdfCreate: React.FC = () => {
  const [errors, setErrors] = React.useState<PdfUploadValidationError[] | null>(null);

  return (
    <>
      一度にアップロードできるPDFは100ファイルまでです。
      <Create resource="pdf">
        <PdfUploadForm setErrors={setErrors} />
      </Create>
      <ErrorMessages errors={errors} />
    </>
  );
};
